// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const Rails = require("@rails/ujs");

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

global.Rails = Rails;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


$(document).on("turbolinks:load", function () {
  $('#transfer-section').hide();
  $('#medication-section').hide();
  $("#lung-sounds-section").hide();
  $("input[id='dispatch[response]_emergency_transfer'").click(function () {
    $("#transfer-section").show();
  });
  $("input[id='dispatch[response]_non_emergency_transfer'").click(function () {
    $("#transfer-section").show();
  });
  $("input[id='dispatch[response]_emergency'").click(function () {
    $("#transfer-section").hide();
  });
  $("input[id='dispatch[response]_non_emergency_911'").click(function () {
    $("#transfer-section").hide();
  });
  $(".transfer-button").click(function () {
    $("#transfer-section").toggle();
  });
  $(".medication-button").click(function () {
    $("#medication-section").toggle();
  });
  $("input[id='dispatch[reason]_other'").click(function () {
    $("input[id='dispatch[complaint]'").val("");
    $("input[id='complaint[chief_complaint]'").val("");
  });
  $("input[id='dispatch[reason]_chest_pain'").click(function () {
    $("input[id='dispatch[complaint]'").val("Chest Pain");
    $("input[id='complaint[chief_complaint]'").val("Chest Pain");
  });
  $("input[id='dispatch[reason]_breathing_problems'").click(function () {
    $("input[id='dispatch[complaint]'").val("Breathing Problems");
    $("input[id='complaint[chief_complaint]'").val("Breathing Problems");
  });
  $("input[id='dispatch[reason]_fall'").click(function () {
    $("input[id='dispatch[complaint]'").val("Fall");
    $("input[id='complaint[chief_complaint]'").val("Fall");
  });
  $("input[id='dispatch[reason]_mva'").click(function () {
    $("input[id='dispatch[complaint]'").val("MVA");
    $("input[id='complaint[chief_complaint]'").val("MVA");
  });
  $("input[id='dispatch[reason]_public_assist'").click(function () {
    $("input[id='dispatch[complaint]'").val("Public Assist");
    $("input[id='complaint[chief_complaint]'").val("Public Assist");
  });
  $("input[id='dispatch[reason]_seizure'").click(function () {
    $("input[id='dispatch[complaint]'").val("Seizure");
    $("input[id='complaint[chief_complaint]'").val("Seizure");
  });
  $("input[id='dispatch[reason]_diabetic_problems'").click(function () {
    $("input[id='dispatch[complaint]'").val("Diabetic Problems");
    $("input[id='complaint[chief_complaint]'").val("Diabetic Problems");
  });
  $("input[id='dispatch[reason]_stroke'").click(function () {
    $("input[id='dispatch[complaint]'").val("Stroke");
    $("input[id='complaint[chief_complaint]'").val("Stroke");
  });
  $("input[id='dispatch[reason]_unconscious'").click(function () {
    $("input[id='dispatch[complaint]'").val("Unconscious");
    $("input[id='complaint[chief_complaint]'").val("Unconscious");
  });
    $("input[id='assessment[chest_physical][no_adventitious_breath_sounds]'").click(function (){
    $("#lung-sounds-section").toggle();
  })
  
  $("#save-hospital-button").click(function (event) {
      var hospitalName = document.getElementById("transport[hospital_name_input]").value;
      var mydata = {
        hospital: {
          name: hospitalName,
        },
      };
    $.ajax({
      type: "POST",
      url: `/hospitals`,
      data: mydata,
      success: function (response) {},
      error: function (response) {},
    });
    $("#hospitals").load(" #hospitals > *");
    $("#add_hospital_form").load(" #add_hospital_form > *"); 
    return false;
  })

  $(".delete").click(function () {
    setTimeout(function(){
      $("#hospitals").load(" #hospitals > *");
    }, 1000);
  });
});

require("trix"),
require("@rails/actiontext")
